import FetchLeafCategoryTagBySlug from '@src/core/useCases/LeafCategoryTag/FetchLeafCategoryTagBySlug';
import Factory from '@src/core/Factory';
import { LeafCategoryTag } from '@src/core/domain/LeafCategoryTag/LeafCategoryTag';
import { FormRenderingOptions } from '@src/core/ApplicationConfiguration';

class LeafCategoryTagService {
  private fetchLeafCategoriesBySlug: FetchLeafCategoryTagBySlug;

  constructor() {
    const leafCategoriesRepository = Factory.newLeafCategoryTagRepository();
    this.fetchLeafCategoriesBySlug = new FetchLeafCategoryTagBySlug(leafCategoriesRepository);
  }

  async getLeafCategoriesAndOptions(
    slug: string,
    currentOptions: Partial<FormRenderingOptions>
  ): Promise<{ options: Partial<FormRenderingOptions>; leafCategories?: LeafCategoryTag }> {
    try {
      const baseOptions = { ...currentOptions, serviceSlug: slug };
      if (slug) {
        const leafCategories = await this.fetchLeafCategoriesBySlug.execute(slug);
        if (leafCategories.subsubcategories.length > 1) {
          const options = this.addSubSubCategoriesToOptions(baseOptions, leafCategories);
          return { options, leafCategories };
        } else if (leafCategories.subsubcategories.length === 1) {
          const options = this.buildSingleSubcategoryOptions(baseOptions, leafCategories);
          return { options, leafCategories };
        } else {
          return { options: baseOptions };
        }
      } else {
        return { options: baseOptions };
      }
    } catch (error) {
      throw new Error('Unable to fetch leaf categories');
    }
  }

  private addSubSubCategoriesToOptions(
    baseOptions: Partial<FormRenderingOptions>,
    leafCategories: LeafCategoryTag
  ): Partial<FormRenderingOptions> {
    const subSubCategoriesNames = leafCategories.subsubcategories.map(
      (subcategory) => subcategory.name
    );

    return {
      ...baseOptions,
      extraConfiguration: {
        ...baseOptions.extraConfiguration,
        subSubCategories: subSubCategoriesNames,
      },
    };
  }

  private buildSingleSubcategoryOptions(
    baseOptions: Partial<FormRenderingOptions>,
    leafCategories: LeafCategoryTag
  ): Partial<FormRenderingOptions> {
    const [subsubcategory] = leafCategories.subsubcategories;
    return {
      enableFindProfessionals: false,
      enableForwardServiceRequest: false,
      serviceSlug: subsubcategory.normalized_name,
      extraConfiguration: {
        ...baseOptions.extraConfiguration,
        skipCategoryStep: true,
      },
    };
  }
}

export default new LeafCategoryTagService();
