import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { adaptValidator, notEmptyValidation } from '@src/ui/helpers/form/validations';
import ValidationError from '@src/ui/helpers/form/ValidationError';

import Category, { CategoryType } from '@src/core/domain/Categories/Category';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';

import { AutocompleteOption } from '@src/ui/components/Input/AsyncAutocomplete';
import Input from '@src/ui/components/Input/Input';
import ButtonLabel from '@src/ui/components/ButtonLabel/ButtonLabel';
import JobTypeAutocomplete from './JobTypeAutocomplete';
import JobTypeAutocompleteOption from './JobTypeAutocompleteOption';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import styles from './JobTypeStep.module.scss';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import {
  CategorySelected,
  jobTypeAutocompleteHidden,
  jobTypeAutocompleteVisible,
  JobTypeSelected,
  JobTypeStepRendered,
  ServiceRequestCanceled,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '../../ButtonGroup';
import NextButton from '../../NextButton';
import { ServiceRequestFormContext } from '../../ServiceRequestFormContext';
import { useForm } from 'react-hook-form';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import formStyles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import Alert, { AlertTypes } from '@src/ui/components/Alert/Alert';
import BackButton from '@src/ui/apps/ServiceRequest/BackButton';
import MostPopularSubcategories from './MostPopularSubcategories';
import mostPopularSubsToSearch from '@src/ui/apps/ServiceRequest/Steps/JobTypeStep/mostPopularSubsToSearch';
import classNames from 'classnames';
import FetchHiddenCategoriesFactory from '@src/core/useCases/Category/FetchHiddenCategoriesFactory';

type JobTypeStepData = {
  categoryName: string;
  jobTypeId: string;
  jobTypeName: string;
};

export type JobTypeStepProps = {
  categoriesTree: CategoryGroupTree;
  subCategories: string[];
  tagLabel: string;
  changeJobTypeLink?: React.ReactNode;
  showSelectedJobType: boolean;
  showHeader: boolean;
  showBackButton: boolean;
  showDesignVariant: boolean;
};

const JobTypeStep = forwardRef<HTMLButtonElement, JobTypeStepProps>(
  (
    {
      categoriesTree,
      subCategories,
      tagLabel,
      changeJobTypeLink,
      showSelectedJobType,
      showHeader,
      showBackButton,
      showDesignVariant,
    }: JobTypeStepProps,
    ref
  ) => {
    const { formData, handleStepCompleted } = React.useContext(ServiceRequestFormContext);
    const EMPTY_CATEGORY = { id: '', name: '', children: [], type: CategoryType.CATEGORY };
    const form = useForm<JobTypeStepData>({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      shouldFocusError: true,
    });
    const helper = useFormHelper(form);
    const [selectedCategory, setSelectedCategory] = useState<Category>(EMPTY_CATEGORY);
    const [jobTypeAutocomplete, setJobTypeAutocomplete] = useState<JobTypeAutocompleteOption>();
    const [backButtonClicked, setBackButtonClicked] = useState<boolean>(false);
    const [hasToClearJobTypeAutocomplete, setHasToClearJobTypeAutocomplete] = useState<boolean>(
      false
    );
    const [checkedJobTypeId, setCheckedJobTypeId] = useState<string>('');
    const fillFormWithDefaultData = (category: Category, job?: Category) => {
      const autocomplete = new JobTypeAutocompleteOption(
        category.id,
        category.name,
        CategoryType.CATEGORY,
        ''
      );
      setSelectedCategory(category);
      setJobTypeAutocomplete(autocomplete);

      if (job) {
        eventBusSingleton.fireEvent(new JobTypeSelected(category, job));
        setCheckedJobTypeId(job.id);
      }
    };

    useEffect(() => {
      if (!categoriesTree.length()) return;
      if (formData?.jobTypeId && !backButtonClicked) {
        const category = categoriesTree.findCategoryByJobTypeId(formData.jobTypeId);
        const job = category.children.find((job) => job.id === formData.jobTypeId);
        fillFormWithDefaultData(category, job);
      } else if (formData?.categoryName && !backButtonClicked && formData?.jobTypeName) {
        const category = categoriesTree.findCategoryByName(formData.categoryName);
        if (category.children.length > 0) {
          const jobTypeName = formData?.jobTypeName || '';
          const selectedJob = jobTypeName
            ? category.children.find(
                (child) => child.name.toLowerCase() === jobTypeName.toLowerCase()
              ) || category.children[0]
            : category.children[0];
          fillFormWithDefaultData(category, selectedJob);
        } else {
          fillFormWithDefaultData(category);
        }
      } else if (formData?.categoryName && !backButtonClicked) {
        const category = categoriesTree.findCategoryByName(formData.categoryName);
        fillFormWithDefaultData(category);
      } else if (backButtonClicked) {
        fillFormWithDefaultData(EMPTY_CATEGORY, EMPTY_CATEGORY);
      }
    }, [categoriesTree, formData, hasToClearJobTypeAutocomplete]);

    useEffect(() => {
      eventBusSingleton.fireEvent(new JobTypeStepRendered());
    }, []);

    useEffect(() => {
      if (selectedCategory !== EMPTY_CATEGORY) {
        eventBusSingleton.fireEvent(new CategorySelected(selectedCategory));
      }
    }, [selectedCategory]);

    const onSubmit = (data: JobTypeStepData) => {
      handleStepCompleted(data);
    };

    let showSelectedJobInput: boolean;
    if (!showDesignVariant) {
      showSelectedJobInput = showSelectedJobType;
    } else {
      showSelectedJobInput = false;
    }

    const showJobTypeAutocomplete =
      categoriesTree && !subCategories.length && (!selectedCategory.id || showSelectedJobInput);

    useEffect(() => {
      if (showDesignVariant) {
        const event = showJobTypeAutocomplete
          ? new jobTypeAutocompleteVisible()
          : new jobTypeAutocompleteHidden();
        eventBusSingleton.fireEvent(event);
      }
    }, [showJobTypeAutocomplete]);

    const showContactAlert = categoriesTree && selectedCategory.id && !showSelectedJobType;

    const findMostPopularSubs = (
      categoriesTree: CategoryGroupTree,
      subcategoriesToSearch: string[]
    ): Category[] => {
      return subcategoriesToSearch
        .map((subcategoryName) => {
          try {
            return categoriesTree.findCategoryByName(subcategoryName);
          } catch (error) {
            return null;
          }
        })
        .filter((subcategory) => subcategory !== null) as Category[];
    };

    const mostPopularSubs = mostPopularSubsToSearch();
    const filteredSubcategories = findMostPopularSubs(categoriesTree, mostPopularSubs);

    const handlePopularCategoryClick = (category: Category) => {
      const autocomplete = new JobTypeAutocompleteOption(
        category.id,
        category.name,
        CategoryType.CATEGORY,
        ''
      );
      setSelectedCategory(category);
      setJobTypeAutocomplete(autocomplete);
    };
    const filterJobTypes = (categoriesTree: CategoryGroupTree, jobTypes: string[]): Category[] => {
      return jobTypes
        .map((subcategoryName) => {
          try {
            return categoriesTree.findCategoryByName(subcategoryName);
          } catch (error) {
            return null;
          }
        })
        .filter((subcategory) => subcategory !== null) as Category[];
    };
    const filterHiddenJobTypes = (
      jobTypes: Category[],
      hiddenCategoriesIds: string[]
    ): Category[] => {
      return jobTypes.filter((jobType) => !hiddenCategoriesIds.includes(jobType.id));
    };
    const hiddenCategories = FetchHiddenCategoriesFactory.create().execute();
    const jobTypes = subCategories.length
      ? filterJobTypes(categoriesTree, subCategories)
      : selectedCategory.children;
    const visibleJobTypes = filterHiddenJobTypes(jobTypes, hiddenCategories);

    const categoryNameLabel = subCategories.length ? tagLabel : selectedCategory.name;
    const stepTitleClass = `step-title${showDesignVariant ? ' step-title--showDesignVariant' : ''}`;

    return (
      <>
        {subCategories.length && !showDesignVariant ? changeJobTypeLink : ''}
        <StepTitle className={stepTitleClass} data-testid="step-title">
          {selectedCategory.name || subCategories.length ? (
            <FormattedMessage
              id="serviceRequestForm.jobTypeStep.header.withCategory"
              defaultMessage="¿Cuál es el tipo de trabajo de {categoryName} que necesitas?"
              description="Título del paso para escoger el tipo de trabajo que contiene una categoría de trabajo preseleccionada"
              values={{ categoryName: categoryNameLabel }}
            />
          ) : (
            <FormattedMessage
              id="serviceRequestForm.jobTypeStep.header.generic"
              defaultMessage="¿Qué tipo de trabajo necesitas?"
              description="Título genérico del paso para escoger el tipo de trabajo"
            />
          )}
        </StepTitle>

        <form
          onSubmit={form.handleSubmit(handleStepCompleted)}
          data-testid="jobTypeForm"
          className={formStyles['serviceRequestForm__form']}
        >
          {helper.errorMessage('categoryName') && showJobTypeAutocomplete && (
            <ValidationError showDesignVariant={showDesignVariant}>
              {helper.errorMessage('categoryName')}
            </ValidationError>
          )}

          {showJobTypeAutocomplete && (
            <JobTypeAutocomplete
              showDesignVariant={showDesignVariant}
              data-testid="job-autocomplete"
              defaultSelected={jobTypeAutocomplete}
              categoriesTree={categoriesTree}
              onChange={(option?: AutocompleteOption) => {
                const jobTypeOption = option as JobTypeAutocompleteOption;
                if (!jobTypeOption) {
                  setSelectedCategory(EMPTY_CATEGORY);
                  return;
                }

                if (jobTypeOption.isCategory()) {
                  const category = categoriesTree.findCategoryById(jobTypeOption.id);
                  setSelectedCategory(category);
                } else {
                  const category = categoriesTree.findCategoryByJobTypeId(jobTypeOption.id);
                  const jobType = {
                    id: jobTypeOption.id,
                    name: jobTypeOption.name,
                    children: [],
                    type: CategoryType.JOB_TYPE,
                  };
                  eventBusSingleton.fireEvent(new JobTypeSelected(category, jobType));
                  onSubmit({
                    jobTypeId: jobTypeOption.id,
                    jobTypeName: jobTypeOption.name,
                    categoryName: jobTypeOption.categoryName,
                  });
                }
              }}
            />
          )}

          {!selectedCategory.name && !subCategories.length ? (
            <MostPopularSubcategories
              popularSubcategories={filteredSubcategories}
              onSubcategoryClick={handlePopularCategoryClick}
              showDesignVariant={showDesignVariant}
            />
          ) : (
            ''
          )}

          <Input
            type="hidden"
            inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
            name="categoryName"
            value={selectedCategory?.name}
            status={helper.inputStatus('categoryName')}
            data-testid="category"
            showDesignVariant={showDesignVariant}
          />

          <div className={styles.category__jobTypes} data-testid="jobTypes-container">
            {(subCategories.length || selectedCategory.children.length !== 0) &&
              showHeader &&
              !showDesignVariant && (
                <p className={styles.category__jobTypes__heading}>
                  <FormattedMessage
                    id="serviceRequestForm.jobTypeStep.jobTypeList.header"
                    defaultMessage="Selecciona el tipo de trabajo que más se adapte"
                    description="Título del paso de selección del tipo de trabajo cuando se selecciona una categoría y muestra el listado de tipos de trabajos"
                  />
                </p>
              )}

            <section
              className={classNames(styles.category__jobTypes__content, {
                [styles['category__jobTypes__content--showDesignVariant']]: showDesignVariant,
              })}
            >
              {helper.errorMessage('jobTypeId') && (
                <ValidationError showDesignVariant={showDesignVariant}>
                  {helper.errorMessage('jobTypeId')}
                </ValidationError>
              )}

              {visibleJobTypes.map((jobType, index, children) => (
                <ButtonLabel
                  key={jobType.id}
                  className={styles.category__jobTypes__entry}
                  showDesignVariant={showDesignVariant}
                >
                  <label htmlFor={jobType.id}>
                    <Input
                      inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
                      type="radio"
                      id={jobType.id}
                      name="jobTypeId"
                      value={jobType.id}
                      defaultChecked={
                        formData?.jobTypeId === jobType.id || checkedJobTypeId === jobType.id
                      }
                      data-testid="jobType"
                      status={helper.inputStatus('jobTypeId')}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const category = categoriesTree.findCategoryByJobTypeId(jobType.id);
                        eventBusSingleton.fireEvent(new JobTypeSelected(category, jobType));
                        onSubmit({
                          ...form.getValues(),
                          categoryName: category.name,
                          jobTypeName: jobType.name,
                        } as JobTypeStepData);
                      }}
                      showDesignVariant={showDesignVariant}
                    />
                    {jobType.name}
                  </label>
                </ButtonLabel>
              ))}
            </section>
          </div>

          <ButtonGroup showDesignVariant={showDesignVariant}>
            {showBackButton && (
              <BackButton
                goBack={() => {
                  eventBusSingleton.fireEvent(new ServiceRequestCanceled());
                }}
              />
            )}

            {showDesignVariant && !showJobTypeAutocomplete && (
              <BackButton
                ref={ref}
                isHidden={true}
                goBack={() => {
                  setBackButtonClicked(true);
                  setHasToClearJobTypeAutocomplete(!hasToClearJobTypeAutocomplete);
                  setSelectedCategory(EMPTY_CATEGORY);
                }}
                showDesignVariant={showDesignVariant}
              />
            )}

            <NextButton
              showDesignVariant={showDesignVariant}
              disabled={showDesignVariant ? !formData?.jobTypeId : false}
            />
          </ButtonGroup>

          {showContactAlert && (
            <Alert variant={AlertTypes.INFORMATION}>
              <span>
                <FormattedMessage
                  id="serviceRequestForm.jobTypeStep.footer.contactAlert"
                  // disable formatjs placeholders rules because it throws an error due to defaultRichTextElements
                  // eslint-disable-next-line formatjs/enforce-placeholders
                  defaultMessage="A continuación enviaremos tu solicitud a los profesionales"
                  description="Mensaje informativo de que se publicará su solicitud"
                />
              </span>
            </Alert>
          )}
        </form>
      </>
    );
  }
);

export default JobTypeStep;
export type { JobTypeStepData };
