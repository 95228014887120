import LeafCategoryTagRepositoryInterface from '@src/core/domain/LeafCategoryTag/LeafCategoryTagRepositoryInterface';
import LeafCategoryTag from '@src/core/domain/LeafCategoryTag/LeafCategoryTag';
import LeafCategoryTagReader from './LeafCategoryTagReader';

type LeafCategoryShape = {
  subsubcategories: {
    name: string;
    normalized_name: string;
  }[];
  tag_name: string;
  subcategory_name: string;
};
class LeafCategoryTagRepository implements LeafCategoryTagRepositoryInterface {
  private reader: LeafCategoryTagReader;

  constructor(reader: LeafCategoryTagReader) {
    this.reader = reader;
  }
  async fetchBySlug(slug: string): Promise<LeafCategoryTag> {
    try {
      const leafCategoryItem = JSON.parse(await this.reader.fetchBySlug(slug)) as LeafCategoryTag;

      return Promise.resolve(leafCategoryItem);
    } catch (err) {
      throw new Error('LeafCategoryTag do not response');
    }
  }

  private map(item: LeafCategoryShape): LeafCategoryTag {
    return {
      subsubcategories: item.subsubcategories.map((subsubcategory) => ({
        name: subsubcategory.name,
        normalized_name: subsubcategory.normalized_name,
      })),
      tag_name: item.tag_name,
      subcategory_name: item.subcategory_name,
    };
  }
}

export default LeafCategoryTagRepository;
