import LeafCategoryTag from '@src/core/domain/LeafCategoryTag/LeafCategoryTag';
import LeafCategoriesRepositoryInterface from '@src/core/domain/LeafCategoryTag/LeafCategoryTagRepositoryInterface';

class FetchLeafCategoryTagBySlug {
  private _repository: LeafCategoriesRepositoryInterface;

  constructor(repository: LeafCategoriesRepositoryInterface) {
    this._repository = repository;
  }

  execute(slug: string): Promise<LeafCategoryTag> {
    return this._repository.fetchBySlug(slug);
  }
}

export default FetchLeafCategoryTagBySlug;
