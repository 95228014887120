import LeafCategoryTagReader from './LeafCategoryTagReader';
import HttpClient from '../http';

class ApiLeafCategoryTagReader implements LeafCategoryTagReader {
  private readonly baseUrl: string;
  private httpClient: HttpClient;

  constructor(baseUrl: string, httpClient: HttpClient) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
  }

  async fetchBySlug(slug: string): Promise<string> {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/leafCategoriesTag/${slug}`);
      return JSON.stringify(response.data());
    } catch (err) {
      throw err;
    }
  }

  readAll(): Promise<string> {
    return Promise.resolve('');
  }

  readSearch(query: string): Promise<string> {
    return Promise.resolve('');
  }
}

export default ApiLeafCategoryTagReader;
